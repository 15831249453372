<template>
  <div id="admin-real-estate-development-create" class="admin-view">
    <v-row no-gutters>
      <v-col md="12">
        <AdvancedQuoter />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
//import AdvancedQuoter from "@/views/real-estate-development/AdminRealEstateDevelopmentQuoteAdvancedView.vue";
export default {
  components: {
    AdvancedQuoter: () =>
      import("@/views/real-estate-development/quoters/AdvancedQuoterView.vue")
  },
  created() {
    this.$emit(`update:layout`, DefaultLayout);
  }
};
</script>
